import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=f55706d6&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js"
import QPage from 'quasar/src/components/page/QPage.js'
import QCarousel from 'quasar/src/components/carousel/QCarousel.js'
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js'
import QImg from 'quasar/src/components/img/QImg.js'
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js'
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js'
import QDialog from 'quasar/src/components/dialog/QDialog.js'
import QCard from 'quasar/src/components/card/QCard.js'
import QBtn from 'quasar/src/components/btn/QBtn.js'
qInstall(component, 'components', {QPage,QCarousel,QCarouselSlide,QImg,QInfiniteScroll,QSpinnerDots,QDialog,QCard,QBtn})
