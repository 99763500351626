import {
  Loading
} from 'quasar'
export default {
  name: 'getContent',
  props: [
    'type',
    'activeContent',
    'clusterTitle'
  ],
  data() {
    return {
      content: '',
      contentpage: 0,
      url_vid:null,
      playerModal:false,
      banner: null,
      contentDetails: null,
      swiperOptionS: {
        slidesPerView: 'auto',
        spaceBetween: 5,
        breakpoints: {
          767: {
            slidesPerView: 3.2,
            spaceBetween: 5
          },
          1365: {
            slidesPerView: 4.5,
            spaceBetween: 5
          },
          1599: {
            slidesPerView: 5.5,
            spaceBetween: 5
          },
          1919: {
            slidesPerView: 6.5,
            spaceBetween: 8
          }
        },
        pagination: {},
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        on: {
          reachEnd: () => {
            if (this.contentpage === 0) return;
            Loading.show();
            this.loadMoreContent();
          }
        }
      },
      subcategoryInDetail: null,
      key_partners: process.env.KEY_PARTNERS
    }
  },
  components: {},
  mounted() {
    this.getContent();
    this.subcategoryInDetail = this.$route.params.subcategoryId
  },
  watch: {
    token(newVal) {
      this.getContent();
      this.subcategoryInDetail = this.$route.params.subcategoryId
    },
    '$route.params.id': {
      handler: function (idNew) {
        this.getContent();
        this.subcategoryInDetail = this.$route.params.subcategoryId
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    token() {
      return this.$store.getters['getTokenApi'];
    },
  },
  methods: {
    getContent() {
      Loading.show();
      let apiUrl = ''
      if (this.type == 'channel') {
        apiUrl = '/api/v2/channels?subcategory_id=554'
      } else if (this.type == 'series') {
        apiUrl = '/api/v5/contents?type=series'
      } else {
        apiUrl = '/api/v5/contents?type=movies'
      }
      this.$apiGet(apiUrl)
        .then(res => {
          if (this.activeContent && this.subcategory_id == this.subcategoryInDetail) {
            let here = this
            this.content = res.data.data.filter(function (obj) {
              return obj.id != here.activeContent.id;
            });
            let newObj = {}
            newObj.image_url = this.activeContent.big_poster
            newObj.isActive = true
            newObj.title = this.activeContent.title
            newObj.id = this.activeContent.id
            newObj.type = this.activeContent.type
            this.content.splice(0, 0, newObj); 
          } else {
            this.content = res.data.data;
          }
          this.contentpage = 1
          Loading.hide()
        })
        .catch(error => {
          Loading.hide()
          console.log(error)
        })
    },
    loadMoreContent() {
      let apiUrl = ''
      if (this.type == 'channel') {
        apiUrl = '/api/v2/channels?subcategory_id=554&page=' + (this.contentpage + 1)
      } else if (this.type == 'series') {
        apiUrl = '/api/v5/contents?type=series&page=' + (this.contentpage + 1)
      } else {
        apiUrl = '/api/v5/contents?type=movies&page=' + (this.contentpage + 1)
      }
      this.$apiGet(apiUrl)
        .then(success => {
          if (this.activeContent) {
            let here = this
            let resData = success.data.data.filter(function( obj ) {
              return obj.id != here.activeContent.id;
            });
            this.content = [...this.content, ...resData]
          } else {
            this.content = [...this.content, ...success.data.data]
          }
          this.contentpage++
          Loading.hide()
        })
        .catch(error => {
          console.error(error)
          Loading.hide()
        })
    }
  },

}
