import ImageContent from '@/components/ImageContent.vue'
export default {
  	name: 'getHome',
  	data () {
      return {
        banners: '',
        bannerModal:false,
        image_popup_web: '',
        listhome: '',
      }
    },
    components: {
      ImageContent
    },
    mounted(){
      this.getBanner();
      this.getList();
    },
    watch:{
      token(newVal){
        this.getBanner();
        this.getList();
      }
    },
    computed:{
      paytv(){
        return this.$store.getters['getPayTv'];
      },
      token(){
        return this.$store.getters['getTokenApi'];
      },
      is_mobile() {
        return this.$store.getters.getPlatform == "mobile" ? true : false;
      },
      userLogin(){
        return this.$store.getters['getIsLogin'];
      }
    },
    methods:{
      getBanner(){
        let platform = this.$store.getters['getPlatform'] == 'desktop' ? 'medium' : 'small';
        this.$apiGet('/api/v5/poster-banners?size='+platform)
        .then(success=>{
          this.banners = success.data.data;
        })
        .catch(error=>{
          console.log(error)
        })
      },
      getList(){
        this.$apiGet('/api/v5/homepage?forWeb=yes&useWebp=yes&is_user_paytv='+this.paytv+'&showAll=false')
        .then(success=>{
          this.listhome = success.data.data;
        })
        .catch(error=>{
          console.log('getlist', error)
        })
      },
      checkIsUserLogin(_content) {
        const nextRoute = '/detail/'+_content.content.type.toLowerCase()+'/'+_content.content.id+'/'+_content.title.replace(/[^a-zA-Z0-9 ]/g, ' ').replace(/\s+/g,' ').trim().split(' ').join('-').toLowerCase()+'/null';
        this.$router.push(nextRoute);
        // if (_content.content.type == "Channel") {
        //   if (this.userLogin) {
        //     const nextRoute = '/'+_content.content.type.toLowerCase()+'/'+_content.content.id+'/'+_content.title.replace(/[^a-zA-Z0-9 ]/g, ' ').replace(/\s+/g,' ').trim().split(' ').join('-').toLowerCase()+'/watch';
        //     this.$router.push(nextRoute);
        //   } else {
        //     if (this.is_mobile) {
        //       this.$router.push('/auth/login')
        //     } else {
        //       this.$store.dispatch('close_login_modal', true);
        //     }
        //   }
        // } else {
        //   const nextRoute = '/'+_content.content.type.toLowerCase()+'/'+_content.content.id+'/'+_content.title.replace(/[^a-zA-Z0-9 ]/g, ' ').replace(/\s+/g,' ').trim().split(' ').join('-').toLowerCase();
        //   this.$router.push(nextRoute);
        // }
      },
      modalBanner(param) {
        this.bannerModal = !this.bannerModal
        this.image_popup_web = param
      }
    },

}
