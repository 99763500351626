//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'image_content',
	props:['url','title','bulkClass','placeholder','hoverData','selected','hoverOn','hoverOff','selecting','disabled','linkContent', 'ratio', 'isActive'],
  	data () {
	    return {
			src:this.placeholder
	    }
	},
	mounted () {
		
	},
	watch:{
		url(newVal){
			this.testImage(newVal);
		}
	},
	methods:{
	    testImage(url) {
	    	this.validateImage(url)
	    	.then(success=>{
	    		this.src = success.src;
	    	})
	    	.catch(error=>{
	    		this.src = this.placeholder
	    	})
	    },
	    validateImage(url){
	        const imgPromise = new Promise(function imgPromise(resolve, reject) {

	            // Create the image
	            const imgElement = new Image();

	            // When image is loaded, resolve the promise
	            imgElement.addEventListener('load', function imgOnLoad() {
	                resolve(this);
	            });

	            // When there's an error during load, reject the promise
	            imgElement.addEventListener('error', function imgOnError() {
	                reject();
	            })

	            // Assign URL
	            // let setSupportWebp = LocalStorage.get.item('webpSupported')
	            // if(!setSupportWebp){
		            // var pos = url.lastIndexOf(".");
					// url = url.substr(0, pos < 0 ? url.length : pos) + ".webp";
	            // }

	            imgElement.src = url;

	        });

	        return imgPromise;
		}
	}
}
