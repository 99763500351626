//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Loading, Dialog } from 'quasar'
import ImageContent from '@/components/ImageContent.vue'
import getContent from '@/mixins/getContent'

export default {
  name: 'swiperContent',
  mixins: [getContent],
  components: {
    ImageContent
  }
}
