//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SwiperContent from "@/components/SwiperContent.vue";
import getHome from "@/mixins/getHome";
import getTheme from "@/mixins/getTheme";
export default {
  name: "PageIndex",
  mixins: [getHome,getTheme],
  data() {
    return {
      page_size: 5,
      page_number: 1,
      splited: [],
      slide: 0
    };
  },
  components: {
    SwiperContent
  },
  mounted() {
    // window.addEventListener("orientationchange", function() {
    //   if(window.innerHeight > window.innerWidth){
    //     alert("Please use Landscape!");
    //   } else {
    //     alert("Please use potrait!");
    //   }
    // }, false);
  },
  methods: {
    paginate(array, page_size, page_number) {
      --page_number; // because pages logically start with 1, but technically with 0
      return array.slice(
        page_number * page_size,
        (page_number + 1) * page_size
      );
    },
    onLoad(index, done) {
      let a = this.listhome;
      let pages = Math.ceil(a.length / this.page_size);
      if (
        this.splited.length <= this.listhome.length &&
        this.page_number <= pages
      ) {
        setTimeout(() => {
          let pagi = this.paginate(
            this.listhome,
            this.page_size,
            this.page_number
          );
          this.splited = [...this.splited, ...pagi];
          this.page_number += 1;
          done();
        }, 2000);
      } else {
        done();
      }
    }
  }
};
